import { Box, Paper } from "@material-ui/core"
import ActionButton from "../../components/common/ActionButton"
import ClickedTile from "../../components/common/ClickedTile"
import { ReactComponent as IconHelp } from "../../assets/svg/icon_help.svg"
import { ReactComponent as IconEnvelope } from "../../assets/svg/icon_envelope.svg"

const MorePage = () => {
  const navigateToHelp = () =>
    (window.location.href = "https://www.multiwash.pl/pomoc/")

  const reportProblem = () =>
    (window.location.href = "https://www.multiwash.pl/kontakt/")

  return (
    <Box>
      <ClickedTile
        icon={<IconHelp />}
        title={"Pomoc"}
        subtitle={"FAQ, kontakt z nami"}
        action={navigateToHelp}
      />
      <ClickedTile
        icon={<IconEnvelope />}
        title={"Zgłoś problem"}
        subtitle={"Wyślij wiadomość z danymi urządzenia"}
        action={reportProblem}
      />
      <Paper elevation={5}>
        <Box
          height="63vh"
          display="flex"
          flexDirection="column-reverse"
          px={2}
          pb={2}
        >
          {/* //TODO: create action or delete button */}
          <ActionButton
            variant={"contained"}
            color={"primary"}
            label={"Wyloguj"}
            fullWidth
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default MorePage
