import { Paper } from "@material-ui/core"
import { useQueryServiceAllOrders } from "../../api/rectQueryHooks/queries/serviveAllOrders"
import OrdersHistoryTile from "../../components/OrdersHistoryTile"

const OrdersHistory = () => {
  const { data: historyOrders } = useQueryServiceAllOrders()

  if (!historyOrders) return null
  else
    return (
      <Paper elevation={5}>
        {historyOrders.map((order, index) => (
          <OrdersHistoryTile key={index} order={order} />
        ))}
      </Paper>
    )
}

export default OrdersHistory
