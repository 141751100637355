import { createSlice } from "@reduxjs/toolkit"

import { IUser } from "../../ts/interfaces/User"

const initialState: IUser = {
  data: null,
  authenticated: false,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setData } = userSlice.actions

export default userSlice.reducer
