import { FC } from "react"
import { Box, Typography } from "@material-ui/core"

import { ReactComponent as IconCodeActive } from "../../assets/svg/icon_code_active.svg"
import { ReactComponent as IconCodeExpired } from "../../assets/svg/icon_code_expired.svg"
import { ReactComponent as IconCodeUsed } from "../../assets/svg/icon_code_used.svg"

import { IOrderResponse } from "../../ts/types/OrderResponse"
import ReservationStatusTile from "../ReservationStatusTile"

type Props = {
  order: IOrderResponse
}

const OrderCodeSection: FC<Props> = ({ order }) => {
  const isExpired = !order.is_valid
  const isUsed = order.is_used
  const isActive = order.is_valid && !order.is_used

  const statusLabel =
    (isActive && "Aktywny") ||
    (isUsed && "Wykorzystany") ||
    (isExpired && "Wygasły")

  const textColor = isActive ? "secondary" : isUsed ? "textSecondary" : "error"

  //TODO: add logic
  const isReservationDate = true

  return (
    <Box mt="-20px" width="100%" px={2}>
      <Typography align="center" variant="subtitle2">
        Kod zamówienia
      </Typography>

      <Typography align="center" variant="h1" color={textColor}>
        {order.code}
      </Typography>

      {isReservationDate ? (
        <ReservationStatusTile />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridGap={10}
        >
          {isActive && <IconCodeActive />}
          {isUsed && <IconCodeUsed />}
          {isExpired && <IconCodeExpired />}
          <Typography variant="subtitle2" color={textColor}>
            {statusLabel}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default OrderCodeSection
