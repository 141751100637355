import { colors } from "../colors"
import { button } from "./button"
import { dialog } from "./dialog"
import { chip } from "./chip"
import { divider } from "./divider"
import { drawer } from "./drawer"
import { formControl } from "./formControl"
import { linearProgress } from "./linearProgress"
import { list } from "./list"
import { paper } from "./paper"
import { radio } from "./radio"
import { typography } from "./typography"

export const overrides = {
  ...button,
  ...chip,
  ...dialog,
  ...divider,
  ...drawer,
  ...formControl,
  ...linearProgress,
  ...list,
  ...paper,
  ...radio,
  ...typography,
  MuiCssBaseline: {
    "@global": {
      body: {
        backgroundColor: colors.white,
      },
    },
  },
}
