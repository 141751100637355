import { FC } from "react"
import { Routes as Switch, Route } from "react-router-dom"

import PrivateRoute from "./PrivateRoute"

import Homepage from "../pages/Homepage"
import Login from "../pages/LoginPage"
import CarWashPage from "../pages/CarWashPage"
import Page404 from "../pages/404"

import Layout from "../layout/Layout"

import { RoutePath } from "./Routes.types"
import OrderAndBookPanel from "../pages/OrderAndBookPanel"
import SelectDatePanel from "../pages/SelectDatePanel"
import Reservation from "../pages/Reservation"
import OrdersHistory from "../pages/OrdersHistory"
import LimitsPage from "../pages/LimitsPage"
import MorePage from "../pages/MorePage"

const Routes: FC = () => {
  const authenticated = true
  return (
    <Switch>
      <Route path="/" element={<Layout />}>
        <Route path={RoutePath.LOGIN} element={<Login />} />
        <Route
          path={RoutePath.HOMEPAGE}
          element={
            <PrivateRoute authenticated={authenticated}>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.CARWASH}
          element={
            <PrivateRoute authenticated={authenticated}>
              <CarWashPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.ORDER}
          element={
            <PrivateRoute authenticated={authenticated}>
              <OrderAndBookPanel />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.SELECT_DATE}
          element={
            <PrivateRoute authenticated={authenticated}>
              <SelectDatePanel />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.RESERVATION}
          element={
            <PrivateRoute authenticated={authenticated}>
              <Reservation />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.ORDERS}
          element={
            <PrivateRoute authenticated={authenticated}>
              <OrdersHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.LIMITS}
          element={
            <PrivateRoute authenticated={authenticated}>
              <LimitsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.MORE}
          element={
            <PrivateRoute authenticated={authenticated}>
              <MorePage />
            </PrivateRoute>
          }
        />

        <Route path={RoutePath.PAGE404} element={<Page404 />} />
      </Route>
    </Switch>
  )
}

export default Routes
