import React, { createContext, useContext, useState } from "react"
import { format } from "date-fns"
import { IOrder } from "../ts/types/Order"
import { IOrderResponse } from "../ts/types/OrderResponse"

type AppProviderValue = {
  selectedPlateNumbers: string[]
  setSelectedPlateNumbers: React.Dispatch<React.SetStateAction<string[]>>
  selectedCarId: string | number
  setSelectedCarId: React.Dispatch<React.SetStateAction<string | number>>
  orderData: IOrder
  setOrderData: React.Dispatch<React.SetStateAction<IOrder>>
  orderResponse: IOrderResponse
  setOrderResponse: React.Dispatch<React.SetStateAction<IOrderResponse>>
  booking: boolean
  setBooking: React.Dispatch<React.SetStateAction<boolean>>
  selectedProgram: string
  setSelectedProgram: React.Dispatch<React.SetStateAction<string>>
  selectedDay: Date | string | null
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | string | null>>
  today: Date
  selectedHour: string
  setSelectedHour: React.Dispatch<React.SetStateAction<string>>
  distance: string
  setDistance: React.Dispatch<React.SetStateAction<string>>
}

const AppContext = createContext<any>({})

export const AppProvider: React.FC = ({ children }) => {
  const [selectedPlateNumbers, setSelectedPlateNumbers] = useState<string[]>([])
  const [selectedCarId, setSelectedCarId] = useState<string | number>("")
  const [selectedProgram, setSelectedProgram] = useState<string>("")
  const [orderData, setOrderData] = useState<IOrder>({
    carwashservice: null,
    car: null,
  })

  const [booking, setBooking] = useState<boolean>(false)

  const [orderResponse, setOrderResponse] = useState<IOrderResponse>({
    car_id: 0,
    car_plate: "",
    carwash_code: 0,
    carwash_name: "",
    code: "",
    created_dt: "",
    currency_symbol: undefined,
    id: 0,
    is_used: false,
    is_valid: false,
    price: 0,
    service_symbol: "",
    used_dt: "",
    valid_dt: "",
  })

  const today = new Date()
  const [selectedDay, setSelectedDay] = useState<Date | string | null>(
    format(today, "yyyy-MM-dd")
  )
  const [selectedHour, setSelectedHour] = useState<string>("")
  const [distance, setDistance] = useState<string>("")

  const value: AppProviderValue = {
    selectedPlateNumbers,
    setSelectedPlateNumbers,
    selectedCarId,
    setSelectedCarId,
    orderData,
    setOrderData,
    orderResponse,
    setOrderResponse,
    booking,
    setBooking,
    selectedProgram,
    setSelectedProgram,
    selectedDay,
    setSelectedDay,
    today,
    selectedHour,
    setSelectedHour,
    distance,
    setDistance,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useCars = () => {
  return useContext(AppContext)
}
