import React, { FC } from "react"

import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Paper,
  Radio,
} from "@material-ui/core"
import { IService } from "../../../ts/types/Service"
import { useCars } from "../../../context/AppContext"
import { IOrder } from "../../../ts/types/Order"

type Props = {
  services?: IService[]
  firstSelectedProgram: number | undefined
}

const SelectProgramSection: FC<Props> = ({
  services,
  firstSelectedProgram,
}) => {
  const { setOrderData, setSelectedProgram } = useCars()
  const defaultValue = firstSelectedProgram?.toString()
  const [value, setValue] = React.useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    setOrderData((prev: IOrder) => {
      return { ...prev, carwashservice: parseInt(event.target.value) }
    })
  }

  return (
    <Box mt={3} mb={2.5}>
      <Typography variant="body2">Wybierz program</Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {services?.map((service) => {
            const isActive = service.active
            const isSelected = value === service.service_symbol
            const activeElevation = isSelected ? 3 : 2

            const description = service.service_description.replace("\r\n", "")
            const descriptionArr = description
              .split("&bull; ")
              .filter((n) => n !== "")
            return (
              <Paper
                elevation={isActive ? activeElevation : 4}
                key={service.carwashservice_id}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormControlLabel
                    value={`${service.carwashservice_id}`}
                    control={
                      <Radio
                        color="secondary"
                        disabled={!isActive}
                        onClick={() =>
                          setSelectedProgram(service.service_symbol)
                        }
                      />
                    }
                    label={service.service_symbol}
                  />
                  {service.price && (
                    <Typography variant="h6">
                      {service.price}&nbsp;
                      {service.currency_symbol}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Box mt="9px">
                  {descriptionArr.map((item, index) => (
                    <List key={index}>
                      <ListItem>
                        <Typography variant="body2">
                          &bull; &nbsp; &nbsp; {item}
                        </Typography>
                      </ListItem>
                    </List>
                  ))}
                </Box>
              </Paper>
            )
          })}
        </RadioGroup>
      </FormControl>

      <Divider />
    </Box>
  )
}

export default SelectProgramSection
