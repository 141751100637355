import { FC } from "react"

import { Box, Paper, Typography } from "@material-ui/core"

import { useCars } from "../../context/AppContext"
import { ReactComponent as IconChevronDown } from "../../assets/svg/icon_chevron_down.svg"

type Props = {
  toggleOpen: () => void
}

const SelectCarSection: FC<Props> = ({ toggleOpen }) => {
  const { selectedPlateNumbers } = useCars()
  return (
    <>
      <Box mb={"6px"}>
        <Typography variant="body2">Wybierz auto</Typography>
      </Box>
      <Paper elevation={1} onClick={toggleOpen}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={1.5}
        >
          <Typography variant="h6" data-cy="plate-number">
            {selectedPlateNumbers}
          </Typography>
          <IconChevronDown />
        </Box>
      </Paper>
    </>
  )
}

export default SelectCarSection
