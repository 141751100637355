const MuiDialog = {
  paper: {
    margin: 16,
    padding: "32px 14px",
    borderRadius: 20,
  },
}

export const dialog = {
  MuiDialog,
}
