import { FC, useState } from "react"

import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core"

import { ReactComponent as IconInfo } from "../../assets/svg/icon_info.svg"
import { ReactComponent as IconClose } from "../../assets/svg/icon_close.svg"
import { useCars } from "../../context/AppContext"

const BookingOnlineTile: FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { booking, setBooking } = useCars()

  const toggleSwitch = () => {
    setBooking((prev: boolean) => !prev)
  }

  const toggleDialogOpen = () => {
    setDialogOpen((prev) => !prev)
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
      >
        <Box display="flex" alignItems="center">
          <Typography>Rezerwacja terminu</Typography>
          <IconButton
            aria-label="back"
            color="secondary"
            onClick={toggleDialogOpen}
          >
            <IconInfo />
          </IconButton>
        </Box>
        <Switch color="primary" checked={booking} onChange={toggleSwitch} />
      </Box>
      <Box>
        <Divider />
      </Box>
      <Dialog onClose={toggleDialogOpen} open={dialogOpen}>
        <Box position="absolute" top="6px" right="8px">
          <IconButton onClick={toggleDialogOpen}>
            <IconClose />
          </IconButton>
        </Box>
        <Box mb={1.5}>
          <Typography variant="h2" color="inherit" align="center">
            Rezerwacja terminu
          </Typography>
        </Box>
        <Typography variant="h6" align="center">
          Gdy zaznaczysz tę opcję, na następnym kroku będziesz móc wybrać termin
          i godzinę, w któej zrealizujesz mycie.
        </Typography>
      </Dialog>
    </>
  )
}

export default BookingOnlineTile
