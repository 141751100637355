import { colors } from "../colors"

const MuiPaper = {
  elevation0: {
    padding: "16px 20px",
    borderRadius: 0,
  },
  elevation1: {
    border: `1px solid ${colors.grayPrimary}`,
    boxShadow: colors.shadow1,
  },
  // available program
  elevation2: {
    marginTop: 12,
    padding: "7px 16px 12px",
    border: `1px solid ${colors.grayPrimary}`,
    boxShadow: colors.shadow2,
  },
  // selected program
  elevation3: {
    marginTop: 12,
    padding: "7px 16px 12px",
    border: `1px solid ${colors.primary}`,
    boxShadow: colors.shadow1,
  },
  // disabled program
  elevation4: {
    marginTop: 12,
    padding: "7px 16px 12px",
    border: `1px solid ${colors.grayPrimary}`,
    boxShadow: colors.shadow3,
    backgroundColor: colors.grayThirdary,
  },
  elevation5: {
    // padding: 16,
    backgroundColor: colors.grayThirdary,
    boxShadow: "none",
  },
  // available hour
  elevation6: {
    marginTop: 4,
    padding: "13px 28px",
    border: `1px solid ${colors.grayPrimary}`,
    boxShadow: colors.shadow2,
  },
  // selected hour
  elevation7: {
    marginTop: 4,
    padding: "13px 28px",
    border: `1px solid ${colors.primary}`,
    boxShadow: colors.shadow1,
  },
  // orders history tile - active program
  elevation8: {
    boxShadow: "none",
    border: `2px solid ${colors.success}`,
    width: "fit-content",
    padding: 4,
  },
  // orders history tile - expired program
  elevation9: {
    boxShadow: "none",
    border: `2px solid ${colors.error}`,
    width: "fit-content",
    padding: 4,
  },
  // orders history tile - used program
  elevation10: {
    boxShadow: "none",
    border: `2px solid ${colors.grayQuaternary}`,
    width: "fit-content",
    padding: 4,
  },
  // orders history tile
  elevation11: {
    marginBottom: 14,
    padding: "20px 16px 14px",
    boxShadow: "none",
    borderRadius: 0,
    borderTop: `1px solid ${colors.graySecondary}`,
    borderBottom: `1px solid ${colors.graySecondary}`,
  },
  elevation12: {
    padding: 12,
    boxShadow: "none",
  },
  //limits program
  elevation13: {
    boxShadow: "none",
    border: `2px solid ${colors.textPrimary}`,
    width: "fit-content",
    padding: 6,
  },
  //bottom menu
  elevation14: {
    boxShadow: colors.shadow4,
    borderRadius: 0,
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "space-evenly",
  },
  //clicked tile
  elevation15: {
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: colors.white,
    display: "flex",
    padding: "24px 10px",
    borderBottom: `1px solid ${colors.graySecondary}`,
  },
}

export const paper = {
  MuiPaper,
}
