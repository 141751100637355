import { FC } from "react"

const LoginPage: FC = () => {
  return (
    <div>
      <p>login page</p>
    </div>
  )
}

export default LoginPage
