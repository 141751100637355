import { useState, useEffect } from "react"
import { ILocation } from "../ts/types/Location"

const useGeoLocation = () => {
  const [location, setLocation] = useState<ILocation>({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  })

  const onSuccess = (location: any) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    })
  }

  const onError = (error: any) => {
    setLocation({
      loaded: true,
      error,
    })
  }

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      })
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError)
  }, [])
  return location
}

export default useGeoLocation
