import { colors } from "../colors"

const MuiRadio = {
  root: {
    backgroundColor: "none",
  },
  colorSecondary: {
    color: colors.primary,

    "&.Mui-checked": {
      color: colors.primary,
    },
  },
}

export const radio = {
  MuiRadio,
}
