import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import { CssBaseline, MuiThemeProvider } from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns" // it has to be 1.x, because of the MUI
import plLocale from "date-fns/locale/pl"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import store from "./store"
import App from "./App"
import theme from "./themes/default/theme"
import { reactQueryClient } from "./api/reactQuery.config"

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={reactQueryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <Router>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </MuiThemeProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
)
