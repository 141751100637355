import { colors } from "../colors"

const MuiFormControl = {
  root: {
    width: "100%",
  },
}

const MuiFormControlLabel = {
  label: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: 700,
  },
}

export const formControl = {
  MuiFormControl,
  MuiFormControlLabel,
}
