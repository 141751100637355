import { useQuery, UseQueryResult } from "react-query"
import { IDriverCars } from "../../../ts/types/DriverCars"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"

const token = {
  access:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NTgyNjM1LCJqdGkiOiI4NDFkNjc2MzIyZmQ0MTY4OGI3YzFjOGMzNDE1YWQ1MyIsImRyaXZlciI6NDAxNTN9.vpqZroqhHC_1D-rpQaNLepQPpWtK_2X5FkZK3foc6Hg",
}

export const useQueryDriverCars = () => {
  const result: UseQueryResult<IDriverCars, Error> = useQuery<
    IDriverCars,
    Error
  >(
    "driver-cars",
    async () => {
      const res = await axios(Endpoint.DRIVER_CARS, {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      })
      return res.data
    },
    {
      staleTime: 20 * 1000,
    }
  )
  return result
}
