import { useState } from "react"
import { Box, Paper, Typography } from "@material-ui/core"
import { useQueryDriverCars } from "../../api/rectQueryHooks/queries/driverCars"
import { useQueryLimitInformation } from "../../api/rectQueryHooks/queries/limitInformation"
import { useCars } from "../../context/AppContext"

import SelectCar from "../../components/SelectCar"
import { ReactComponent as IconLogo } from "../../assets/svg/icon_logo2.svg"
import LimitTile from "../../components/LimitTile"

const LimitsPage = () => {
  const { data: driverCars } = useQueryDriverCars()
  const { selectedCarId } = useCars()
  const firstCarId = driverCars?.length && driverCars[0].id.toString()
  const carId = selectedCarId.length > 0 && selectedCarId

  const { data: limitInformation } = useQueryLimitInformation(
    `${firstCarId}` || `${carId}`,
    {
      enabled: !!driverCars,
    }
  )

  const [toggleSelectOpen, setToggleSelectOpen] = useState(false)

  const quantityLimit =
    limitInformation !== undefined &&
    limitInformation.find((el) =>
      el.service_configurations.find((el) => el.limit && !el.limit_money)
    )

  const quotaLimitLimited =
    limitInformation !== undefined &&
    limitInformation.find((el) =>
      el.service_configurations.find((el) => !el.limit && el.limit_money)
    )
  const quotaLimitUnlimited =
    limitInformation !== undefined &&
    limitInformation.find((el) =>
      el.service_configurations.find((el) => !el.limit && !el.limit_money)
    )

  const quotaLimit = quotaLimitLimited || quotaLimitUnlimited

  if (!driverCars || !limitInformation) return null
  else
    return (
      <>
        <Box>
          <SelectCar
            driverCars={driverCars}
            toggleSelectOpen={toggleSelectOpen}
            setToggleSelectOpen={setToggleSelectOpen}
          />
        </Box>
        <Paper elevation={0} onClick={() => setToggleSelectOpen(false)}>
          <Box mt="-30px" display="flex" justifyContent="center">
            <IconLogo />
          </Box>
          <Box mt="-30px">
            {quantityLimit && (
              <LimitTile
                title={quantityLimit.contract_name}
                periodStart={quantityLimit.period_start}
                periodEnd={quantityLimit.period_end}
                limitTypeName={quantityLimit.limit_type_name}
                configurations={quantityLimit.service_configurations}
                isQuantity={true}
              />
            )}
            {quotaLimit && (
              <LimitTile
                title={quotaLimit.contract_name}
                periodStart={quotaLimit.period_start}
                periodEnd={quotaLimit.period_end}
                limitTypeName={quotaLimit.limit_type_name}
                configurations={quotaLimit.service_configurations}
                isQuotaLimit={true}
                currencySymbol={quotaLimit.currency_symbol}
              />
            )}
            {limitInformation.length === 0 && (
              <Typography>Brak limitów</Typography>
            )}
          </Box>
        </Paper>
      </>
    )
}
export default LimitsPage
