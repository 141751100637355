import { colors } from "../colors"

const MuiLinearProgress = {
  root: {
    height: 12,
    borderRadius: 20,
    border: `2px solid ${colors.graySecondary}`,
  },
  colorPrimary: {
    backgroundColor: colors.graySecondary,
  },
  barColorPrimary: {
    backgroundColor: colors.success,
    borderRadius: 10,
  },
}

export const linearProgress = {
  MuiLinearProgress,
}
