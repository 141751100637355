import { FC } from "react"
import { Outlet } from "react-router-dom"
import BottomMenu from "../components/common/BottomMenu"
import Navbar from "../components/common/Navbar"

const Layout: FC = () => {
  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main style={{ height: "85vh", overflowY: "scroll" }}>
        <Outlet />
      </main>
      <footer
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <BottomMenu />
      </footer>
    </div>
  )
}

export default Layout
