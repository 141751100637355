import { useMutation } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"

import { IOrder } from "../../../ts/types/Order"
import { IOrderResponse } from "../../../ts/types/OrderResponse"

const token = {
  access:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NTgyNjM1LCJqdGkiOiI4NDFkNjc2MzIyZmQ0MTY4OGI3YzFjOGMzNDE1YWQ1MyIsImRyaXZlciI6NDAxNTN9.vpqZroqhHC_1D-rpQaNLepQPpWtK_2X5FkZK3foc6Hg",
}

export const useMutationPostOrder = () => {
  return useMutation<IOrderResponse[], AxiosError, IOrder>(
    async ({ carwashservice, car, booking_time }) => {
      const response = await axios.post(
        "/service_orders/",
        {
          carwashservice,
          car,
          booking_time,
        },
        {
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        }
      )
      return response.data
    }
  )
}
