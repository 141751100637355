import { FC } from "react"
import { format } from "date-fns"

import { Chip } from "@material-ui/core"
import { IOpeningHours } from "../../../ts/types/OpeningHours"
import { IDayOfWeek } from "../../../ts/types/DayOfWeek"

type Props = {
  open_24h: boolean
  opening_hours: IOpeningHours[]
}

const StatusBadge: FC<Props> = ({ open_24h, opening_hours }) => {
  const today = new Date()
  const actualDay = format(today, "EEEEEE")
  const actualHour = parseInt(format(today, "H"))
  let dayOfWeek: IDayOfWeek

  if (actualDay === "Mo") {
    dayOfWeek = "Poniedziałek"
  } else if (actualDay === "Tu") {
    dayOfWeek = "Wtorek"
  } else if (actualDay === "We") {
    dayOfWeek = "Środa"
  } else if (actualDay === "Th") {
    dayOfWeek = "Czwartek"
  } else if (actualDay === "Fr") {
    dayOfWeek = "Piątek"
  } else if (actualDay === "Sa") {
    dayOfWeek = "Sobota"
  } else if (actualDay === "Su") {
    dayOfWeek = "Niedziela"
  }

  const findDay = opening_hours.filter((item) => item.day === dayOfWeek)
  const closingHour = findDay.length > 0 && parseInt(findDay[0].closing_hour)
  const openingHour = findDay.length > 0 && parseInt(findDay[0].opening_hour)

  const isOpenNow = actualHour >= openingHour && actualHour < closingHour
  const isOpen = open_24h ? true : isOpenNow ? true : false

  return (
    <Chip
      label={isOpen ? "Czynne" : "Nieczynne"}
      color={isOpen ? "secondary" : "primary"}
    />
  )
}

export default StatusBadge
