import { colors } from "../colors"

const MuiButton = {
  root: {
    fontSize: 16,
    fontWeight: 400,
    padding: "14px 24px",
  },
  textSecondary: {
    padding: "14px 20px",
    color: colors.error,
  },
  containedSecondary: {
    backgroundColor: colors.error,
    boxShadow: colors.buttonShadow,
  },
  // logout button
  containedPrimary: {
    display: "none",
  },
  outlinedPrimary: {
    padding: 14,
    color: colors.textSecondary,
    border: `1px solid ${colors.textSecondary}`,
  },
  outlinedSecondary: {
    padding: 14,
    color: colors.error,
    border: `1px solid ${colors.error}`,
  },
}

const MuiIconButton = {
  colorPrimary: {
    backgroundColor: colors.white,
    borderRadius: 4,
    border: `1px solid ${colors.grayPrimary}`,
    width: 44,
    height: 44,
    zIndex: 1,
  },
  colorSecondary: {
    paddingLeft: 4,
    borderRadius: 0,
  },
  //menu item active
  edgeStart: {
    marginLeft: 0,
    borderRadius: 0,
    padding: 16,
    borderTop: `2px solid ${colors.error}`,
    background:
      "linear-gradient(180deg, rgba(228, 31, 37, 0.1) 0%, rgba(228, 31, 37, 0) 100%)",
  },
  // menu item
  edgeEnd: {
    marginRight: 0,
    borderRadius: 0,
    padding: 16,
    borderTop: `2px solid ${colors.white}`,
  },
}

export const button = {
  MuiButton,
  MuiIconButton,
}
