import { FC } from "react"
import { Box, Paper, Typography } from "@material-ui/core"
import { ReactComponent as IconChevron } from "../../../assets/svg/icon_chevron_right.svg"
type Props = {
  icon: React.ReactNode
  title: string
  subtitle: string
  action?: any
}

const ClickedTile: FC<Props> = ({ icon, title, subtitle, action }) => {
  return (
    <Paper elevation={15} onClick={action}>
      {icon}
      <Box flexGrow={1} ml={1}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
      {action && (
        <Box
          mr="6px"
          display="flex"
          alignItems="center"
          component={action ? "a" : "div"}
        >
          <IconChevron />
        </Box>
      )}
    </Paper>
  )
}

export default ClickedTile
