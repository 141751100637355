import { createTheme } from "@material-ui/core"

import { palette } from "./palette"
import { overrides } from "./overrides/overrides"

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  palette,
  overrides,
})

export default theme
