import { colors } from "../colors"

const MuiChip = {
  root: {
    fontSize: 14,
    width: 89,
  },
  colorPrimary: {
    color: colors.textSecondary,
    backgroundColor: colors.grayPrimary,
  },
  colorSecondary: {
    backgroundColor: colors.success,
  },
}

export const chip = {
  MuiChip,
}
