import { AppProvider } from "./context/AppContext"
import Routes from "./routes/Routes"

const App = () => {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  )
}

export default App
