export const colors: Record<string, string> = {
  primary: "#0E5B98",
  textPrimary: "#333",
  textSecondary: "#636363",
  error: "#E41F25",
  success: "#15847F",
  grayPrimary: "#E5E5E5",
  graySecondary: "#E3ECF3",
  grayThirdary: "#F2F5F6",
  grayQuaternary: "#A5A5A5",
  grayQuinary: "#787885",
  white: "#fff",

  shadow1: "0px 2px 16px 0px #0D3A5C1F",
  shadow2: "0px 2px 16px 0px #0D3A5C14",
  shadow3: "0px 2px 16px 0px #0D3A5C0A",
  shadow4: "0px 0px 24px 0px #0E5B9729",
  buttonShadow: "0px 4px 22px rgba(228, 31, 37, 0.29)",
}
