const MuiDrawer = {
  paper: {
    padding: "8px 16px 20px",
    borderRadius: "20px 20px 0 0",
    borderTop: "none",
  },
}

export const drawer = {
  MuiDrawer,
}
