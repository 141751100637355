import { FC, useEffect } from "react"
import { Box, Divider, Paper, Typography } from "@material-ui/core"

import { ReactComponent as IconChevronDown } from "../../assets/svg/icon_chevron_down.svg"
import { ReactComponent as IconCheck } from "../../assets/svg/icon_check.svg"

import { ICar } from "../../ts/types/Car"
import { IDriverCars } from "../../ts/types/DriverCars"
import { useCars } from "../../context/AppContext"

const selectItemStyles = {
  position: "absolute",
  bgcolor: "white",
  width: "100%",
}

type Props = {
  driverCars: IDriverCars
  toggleSelectOpen: boolean
  setToggleSelectOpen: any
}

const SelectCar: FC<Props> = ({
  driverCars,
  toggleSelectOpen,
  setToggleSelectOpen,
}) => {
  const firstCarPlate = driverCars?.length && driverCars[0].plate
  useEffect(() => {
    setSelectedPlateNumbers(firstCarPlate)
  }, [])

  const { selectedPlateNumbers, setSelectedPlateNumbers, setSelectedCarId } =
    useCars()

  const handleClick = (clickedCar: ICar) => {
    setSelectedPlateNumbers(() => {
      setToggleSelectOpen(false)
      return clickedCar.plate
    })
    setSelectedCarId(clickedCar.id)
  }

  const isOpen = toggleSelectOpen ? "flex" : "none"

  return (
    <>
      <Box>
        <Paper
          elevation={12}
          onClick={() => setToggleSelectOpen(!toggleSelectOpen)}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridGap={2}
          >
            <Typography variant="h2" color="inherit" data-cy="limits-plate">
              {selectedPlateNumbers}
            </Typography>
            <IconChevronDown />
          </Box>
        </Paper>
      </Box>
      <Box display={isOpen} flexDirection="column" sx={selectItemStyles}>
        {driverCars.map((car) => (
          <div key={car.id}>
            <Box
              onClick={() => handleClick(car)}
              display="flex"
              justifyContent="space-between"
              py={3}
              pl={2}
              pr={4}
            >
              <Typography variant="h6">{car.plate}</Typography>
              {selectedPlateNumbers.includes(car.plate) && <IconCheck />}
            </Box>
            <Divider />
          </div>
        ))}
      </Box>
    </>
  )
}

export default SelectCar
