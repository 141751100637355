import { FC, useState } from "react"
import { Box, Divider, Typography } from "@material-ui/core"

import ActionButton from "../common/ActionButton"

import { ReactComponent as IconClock } from "../../assets/svg/icon_clock.svg"
import { ReactComponent as IconCalendarConfirmed } from "../../assets/svg/icon_calendar_confirmed.svg"
import { ReactComponent as IconCalendarCancelled } from "../../assets/svg/icon_calendar_cancelled.svg"
import CancelReservationDrawer from "../CancelReservationDrawer"

const ReservationStatusTile: FC = () => {
  const [toggleDrawerOpen, setToggleDrawerOpen] = useState(false)
  //TODO: add logic based on api
  const isWaitingForConfirmation = true
  const isConfirmed = false
  const isCancelledByClient = false
  const isCancelledByCarWash = false
  const isCancelled = isCancelledByClient || isCancelledByCarWash

  const textColor = isConfirmed ? "secondary" : "textPrimary"

  let label
  if (isWaitingForConfirmation) label = "Rezerwacja oczekuje na potwierdzenie"
  else if (isConfirmed) label = "Termin potwierdzony przez myjnię"
  else if (isCancelledByClient) label = "Zrezygnowano z terminu"
  else if (isCancelledByCarWash) label = "Termin anulowany przez myjnię"

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={2}
      position="relative"
    >
      <Divider />
      <Box mt={2}>
        {isWaitingForConfirmation ? (
          <IconClock />
        ) : isConfirmed ? (
          <IconCalendarConfirmed />
        ) : (
          <IconCalendarCancelled />
        )}
      </Box>
      <Typography variant="subtitle2" color={textColor}>
        {label}
      </Typography>
      {/* TODO: add correct date */}
      <Typography variant="h6">Rezerwacja: 22.02.2022, 14:45</Typography>
      {isCancelled && (
        <Box
          position="absolute"
          height="1px"
          width={226}
          top={93}
          sx={{ bgcolor: "#333" }}
        />
      )}
      <Box display="flex" gridGap={16} width="100%" mt={2.5}>
        {isWaitingForConfirmation && (
          <ActionButton
            variant="outlined"
            label="Anuluj termin"
            color="primary"
            fullWidth
            action={() => setToggleDrawerOpen(true)}
          />
        )}
        <ActionButton
          variant="outlined"
          label="Zmień termin"
          color="secondary"
          fullWidth
          // action={}
        />
      </Box>
      <CancelReservationDrawer
        toggleDrawerOpen={toggleDrawerOpen}
        setToggleDrawerOpen={setToggleDrawerOpen}
      />
    </Box>
  )
}

export default ReservationStatusTile
