import axios from "axios"

import { API_URL } from "./api.config"

// const token = {
//   access:
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NTgyNjM1LCJqdGkiOiI4NDFkNjc2MzIyZmQ0MTY4OGI3YzFjOGMzNDE1YWQ1MyIsImRyaXZlciI6NDAxNTN9.vpqZroqhHC_1D-rpQaNLepQPpWtK_2X5FkZK3foc6Hg",
// }

const defaultOptions = {
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token.access}`,
  },
}

const axiosInstance = axios.create(defaultOptions)

export default axiosInstance
