import { FC } from "react"
import { Box, Paper, Typography } from "@material-ui/core"
import { useCars } from "../../context/AppContext"

type Props = {
  name: string
}

const SelectedDetails: FC<Props> = ({ name }) => {
  const { selectedPlateNumbers, selectedProgram } = useCars()

  return (
    <Paper elevation={5}>
      <Box display="flex" gridGap={16} p={2}>
        <Box>
          <Typography variant="body2">Myjnia</Typography>
          <Typography variant="body2">Auto</Typography>
          <Typography variant="body2">Program</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="body1">{selectedPlateNumbers}</Typography>
          <Typography variant="body1">{selectedProgram}</Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default SelectedDetails
