import { FC, useEffect } from "react"
import { Box, Divider, Paper } from "@material-ui/core"
import SelectedDetails from "../../components/SelectedDetails"
import { useNavigate, useParams } from "react-router"
import { useQueryCarWashData } from "../../api/rectQueryHooks/queries/carwashData"
import SelectDay from "../../components/SelectDay"
import SelectHour from "../../components/SelectHour"
import ActionButton from "../../components/common/ActionButton"
import { useCars } from "../../context/AppContext"
import { useMutationPostOrder } from "../../api/rectQueryHooks/mutations/serviceOrders"
import { IOrder } from "../../ts/types/Order"
// import { useQueryAvailableTerms } from "../../api/rectQueryHooks/queries/availableTerms"

const SelectDatePanel: FC = () => {
  const { id } = useParams()
  const { data: carwashData } = useQueryCarWashData(id || "")
  // const { data: availableTerms } = useQueryAvailableTerms(189782, 48006)
  const { mutate, data: postResponse } = useMutationPostOrder()
  const navigate = useNavigate()

  const {
    orderData,
    selectedDay,
    selectedHour,
    setOrderData,
    setOrderResponse,
  } = useCars()

  const addOrderTime = () => {
    const bookingDate = new Date(
      [selectedDay, selectedHour, "UTC"].join(" ")
    ).toISOString()

    setOrderData((prev: IOrder) => {
      return { ...prev, booking_time: bookingDate }
    })
  }

  useEffect(() => {
    if (selectedHour !== 0) {
      addOrderTime()
    }
  }, [selectedDay, selectedHour])

  const handleCreateOrder = (e: any) => {
    e.preventDefault()
    addOrderTime()
    mutate(orderData)
  }

  useEffect(() => {
    setOrderResponse(postResponse)
    if (postResponse !== undefined) {
      //@ts-ignore
      navigate(`/carwash/${id}/reservation/${postResponse.id} `)
    }
  }, [postResponse])

  if (!carwashData) return null
  else
    return (
      <>
        <SelectedDetails name={carwashData.name} />
        <Paper elevation={0}>
          <SelectDay />
          <Divider />
          <SelectHour />
          <Divider />
          <Box mt={2.5}>
            <ActionButton
              variant="contained"
              color="secondary"
              label={"Zamawiam i rezerwuję"}
              fullWidth
              action={handleCreateOrder}
            />
          </Box>
        </Paper>
      </>
    )
}

export default SelectDatePanel
