import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { useQueryCarWashData } from "../../api/rectQueryHooks/queries/carwashData"

import CarWashDetailsDrawer from "../../components/module/CarWashDetailsDrawer"
import MapSection from "../../components/MapSection"

const CarWashPage: FC = () => {
  const { id } = useParams()
  const { data } = useQueryCarWashData(id || "")
  const [toggleDrawerOpen, setToggleDrawerOpen] = useState(true)

  if (!data) return null
  else
    return (
      <>
        <MapSection
          location_lat={data.location_lat}
          location_lng={data.location_lng}
          setToggleDrawerOpen={setToggleDrawerOpen}
        />
        <CarWashDetailsDrawer
          toggleDrawerOpen={toggleDrawerOpen}
          setToggleDrawerOpen={setToggleDrawerOpen}
          carWashData={data}
        />
      </>
    )
}

export default CarWashPage
