export enum RoutePath {
  HOMEPAGE = "/",
  CARWASH = "/carwash/:id/",
  ORDER = "/carwash/:id/order/",
  SELECT_DATE = "/carwash/:id/order/date/",
  RESERVATION = "/carwash/:id/reservation/:order_id/",
  FAVORITE = "/favorite/",
  LIMITS = "/limits/",
  ORDERS = "/orders/",
  MORE = "/more",
  LOGIN = "/login/",
  PAGE404 = "*",
}
