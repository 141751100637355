import { FC } from "react"

import { Button } from "@material-ui/core"

type Props = {
  variant: "text" | "outlined" | "contained"
  label: string
  color: "primary" | "secondary"
  action?: any
  icon?: React.ReactNode
  fullWidth?: boolean
  href?: string
}

const ActionButton: FC<Props> = ({
  variant,
  label,
  color,
  icon,
  action,
  fullWidth,
  href,
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      startIcon={icon}
      onClick={action}
      fullWidth={fullWidth}
      component={href ? "a" : "button"}
      href={href}
    >
      {label}
    </Button>
  )
}

export default ActionButton
