const MuiList = {
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}

const MuiListItem = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  gutters: {
    paddingLeft: 6,
  },
}

export const list = {
  MuiList,
  MuiListItem,
}
