import { colors } from "../colors"

const MuiTypography = {
  root: {
    color: colors.textPrimary,
  },
  h1: {
    fontSize: 40,
    fontWeight: 700,
  },
  h2: {
    fontSize: 20,
    fontWeight: 400,
    color: colors.grayQuaternary,
  },
  h3: {
    fontSize: 20,
    fontWeight: 900,
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 400,
  },
  h4: {
    fontSize: 16,
    fontWeight: 900,
  },
  h5: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
  },
  h6: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
  },
  paragraph: {
    fontSize: 16,
    fontWeight: 300,
    marginBottom: 7,
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
  },
  body2: {
    fontSize: 14,
    fontWeight: 300,
  },
  colorPrimary: {
    color: colors.primary,
  },
  colorSecondary: {
    color: colors.success,
  },
  colorTextPrimary: {
    color: colors.grayQuinary,
  },
}

export const typography = {
  MuiTypography,
}
