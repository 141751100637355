import { colors } from "./colors"

export const palette = {
  primary: {
    main: colors.primary,
  },
  error: {
    main: colors.error,
  },
}
