import { FC } from "react"

import { Box } from "@material-ui/core"

import { ReactComponent as IconSofa } from "../../assets/svg/icon_sofa.svg"
import { ReactComponent as IconWifi } from "../../assets/svg/icon_wifi.svg"
import { ReactComponent as IconSpa } from "../../assets/svg/icon_spa.svg"
import { ReactComponent as IconDisinfection } from "../../assets/svg/icon_disinfection.svg"
import { ReactComponent as Icon24h } from "../../assets/svg/icon_24h.svg"
import { ReactComponent as IconTires } from "../../assets/svg/icon_tires.svg"
import { ReactComponent as IconOzon } from "../../assets/svg/icon_ozon.svg"
import { ReactComponent as IconMall } from "../../assets/svg/icon_mall.svg"

import { ICarWashFeatures } from "../../ts/types/CarWashFeatures"
import MaxHeightSection from "../MaxHeightSection"

type Props = ICarWashFeatures

const CarWashFeaturesSection: FC<Props> = ({
  waiting_room,
  wifi,
  car_spa,
  disinfection,
  open_24h,
  tire_service,
  ozonation,
  shopping_center,
  max_car_height,
}) => {
  // TODO: verify svg icons and add missing
  return (
    <Box display="flex" flexWrap="wrap" gridGap={18}>
      {waiting_room && <IconSofa />}
      {wifi && <IconWifi />}
      {car_spa && <IconSpa />}
      {disinfection && <IconDisinfection />}
      {open_24h && <Icon24h />}
      {tire_service && <IconTires />}
      {ozonation && <IconOzon />}
      {shopping_center && <IconMall />}
      <MaxHeightSection max_car_height={max_car_height} />
    </Box>
  )
}

export default CarWashFeaturesSection
