import { colors } from "../colors"

const MuiDivider = {
  root: {
    backgroundColor: colors.graySecondary,
    width: "100%",
  },
  light: {
    margin: "0 auto",
    width: 80,
    height: 4,
    backgroundColor: colors.grayPrimary,
    borderRadius: 2,
  },
}

export const divider = {
  MuiDivider,
}
