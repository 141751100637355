import { FC, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import { useQueryCarWashData } from "../../api/rectQueryHooks/queries/carwashData"
import { useQueryDriverCars } from "../../api/rectQueryHooks/queries/driverCars"
import { useQueryServices } from "../../api/rectQueryHooks/queries/services"
import { useMutationPostOrder } from "../../api/rectQueryHooks/mutations/serviceOrders"

import { Box, Divider, Paper } from "@material-ui/core"

import ActionButton from "../../components/common/ActionButton"
import BookingOnlineTile from "../../components/BookingOnlineTile"
import CarWashDetailsSection from "../../components/CarWashDetailsSection"
import SelectCarDrawer from "../../components/module/SelectCarDrawer"
import SelectCarSection from "../../components/SelectCarSection"
import SelectProgramSection from "../../components/module/SelectProgramSection"
import { useCars } from "../../context/AppContext"

const OrderAndBookPanel: FC = () => {
  const { id } = useParams()
  const carwashId = id || ""
  const {
    orderData,
    setOrderData,
    setSelectedPlateNumbers,
    setOrderResponse,
    booking,
    setSelectedProgram,
  } = useCars()
  const navigate = useNavigate()
  const { data: carwashData } = useQueryCarWashData(id || "")
  const { data: driverCars } = useQueryDriverCars()
  const { mutate, data: postResponse } = useMutationPostOrder()

  const firstCarId = driverCars?.length && driverCars[0].id
  const firstCarPlate = driverCars?.length && driverCars[0].plate
  const urlPath = carwashData?.online_booking ? "order/date" : "reservation"

  const { data: services } = useQueryServices(
    carwashId,
    `${firstCarId}` || `${orderData?.car}`,
    { enabled: !!driverCars }
  )

  const firstSelectedProgram = services?.length && services[0].carwashservice_id
  const firstSelectedProgramSymbol =
    services?.length && services[0].service_symbol

  const isDriverOnlineBooking =
    driverCars?.length && driverCars[0].online_booking
  const isOnlineBooking = carwashData?.online_booking && isDriverOnlineBooking

  useEffect(() => {
    setOrderData({ carwashservice: firstSelectedProgram, car: firstCarId })
    setSelectedPlateNumbers(firstCarPlate)
    setSelectedProgram(firstSelectedProgramSymbol)
  }, [driverCars, services])

  const [toggleOpenDrawer, setToggleOpenDrawer] = useState(false)

  const toggleOpen = () => {
    setToggleOpenDrawer((prev) => !prev)
  }

  const handleCreateOrder = (e: any) => {
    e.preventDefault()
    mutate(orderData)
  }

  const navigateToSelectDate = (e: any) => {
    e.preventDefault()
    navigate(`/carwash/${id}/${urlPath}`)
  }

  useEffect(() => {
    setOrderResponse(postResponse)
    if (postResponse !== undefined) {
      //@ts-ignore
      navigate(`/carwash/${id}/reservation/${postResponse.id} `)
    }
  }, [postResponse])

  if (!carwashData || !driverCars || !services) return null
  else
    return (
      <Paper elevation={0}>
        <CarWashDetailsSection carWashData={carwashData} />
        <Box mt={0.5} mb={2.5}>
          <Divider />
        </Box>
        <SelectCarSection toggleOpen={toggleOpen} />
        <SelectCarDrawer
          toggleOpenDrawer={toggleOpenDrawer}
          toggleOpen={toggleOpen}
          driverCars={driverCars}
        />
        <Box mt={3}>
          <Divider />
        </Box>
        {/* //TODO: add variable name "isOnlineBooking" to BookingOnlineTile  */}
        {carwashData.online_booking && <BookingOnlineTile />}

        <SelectProgramSection
          services={services}
          firstSelectedProgram={firstSelectedProgram}
        />

        <ActionButton
          variant="contained"
          label={booking ? "Dalej" : "Zamawiam"}
          color="secondary"
          fullWidth={true}
          // href={`/carwash/${id}/${urlPath}`}
          action={booking ? navigateToSelectDate : handleCreateOrder}
        />
      </Paper>
    )
}

export default OrderAndBookPanel
