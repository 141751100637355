import { useQuery, UseQueryResult } from "react-query"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"

import { ICarWash } from "../../../ts/types/CarWash"

export const useQueryCarWashData = (id: string) => {
  const result: UseQueryResult<ICarWash, Error> = useQuery<ICarWash, Error>(
    ["carwash", id],
    async () => {
      const res = await axios(Endpoint.CARWASH + id)
      return res.data
    },
    {
      staleTime: 20 * 1000,
    }
  )
  return result
}
