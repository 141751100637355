import { FC } from "react"

import { Box, Divider, Drawer, Typography } from "@material-ui/core"

import { useCars } from "../../../context/AppContext"

import { ReactComponent as IconCheck } from "../../../assets/svg/icon_check.svg"

import { IDriverCars } from "../../../ts/types/DriverCars"
import { ICar } from "../../../ts/types/Car"
import { IOrder } from "../../../ts/types/Order"

type Props = {
  toggleOpenDrawer: boolean
  toggleOpen: () => void
  driverCars: IDriverCars
}

const SelectCarDrawer: FC<Props> = ({
  toggleOpenDrawer,
  toggleOpen,
  driverCars,
}) => {
  const { selectedPlateNumbers, setSelectedPlateNumbers, setOrderData } =
    useCars()

  const handleClick = (clickedCar: ICar) => {
    setOrderData((prev: IOrder) => {
      const isCarInState = prev.car === clickedCar.id
      if (isCarInState) {
        return { ...prev }
      } else return { ...prev, car: clickedCar.id }
    })
    setSelectedPlateNumbers(clickedCar.plate)
    toggleOpen()
  }

  return (
    <Drawer open={toggleOpenDrawer} anchor="bottom" onClose={toggleOpen}>
      <Divider light />
      <Box my={2}>
        <Typography variant="body2">Wybierz auto</Typography>
      </Box>
      <Divider />
      {driverCars.map((car) => (
        <div key={car.id}>
          <Box
            onClick={() => handleClick(car)}
            display="flex"
            justifyContent="space-between"
            py={3}
            pr={2}
          >
            <Typography variant="h6">{car.plate}</Typography>
            {selectedPlateNumbers.includes(car.plate) && <IconCheck />}
          </Box>
          <Divider />
        </div>
      ))}
    </Drawer>
  )
}

export default SelectCarDrawer
