import React from "react"
import "date-fns"
import { format } from "date-fns"
import DateFnsUtils from "@date-io/date-fns/"
import { Box } from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import { useCars } from "../../context/AppContext"
import { IOrder } from "../../ts/types/Order"

const SelectDay = () => {
  const { selectedDay, setSelectedDay } = useCars()

  const handleDateChange = (date: Date | string | null) => {
    //@ts-ignore
    setSelectedDay(format(date, "yyyy-MM-dd"))
  }

  return (
    <Box mt="-12px" mb={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          color="primary"
          id="date-picker-dialog"
          label="Wybierz termin mycia"
          format="dd.MM.yyyy"
          value={selectedDay}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}

export default SelectDay
