import React, { useEffect } from "react"
import { isAfter, format } from "date-fns"
import { Box, Grid, Paper, Typography } from "@material-ui/core"
import { mockData } from "./mockdata"
import { useCars } from "../../context/AppContext"

const SelectHour = () => {
  const { selectedDay, selectedHour, setSelectedHour } = useCars()

  const data = mockData[1]
  const availableHours = data.filter((el) => el.date === selectedDay)
  const hours = availableHours?.length ? availableHours[0].times : ""

  const afternoon = "14:00"
  const evening = "17:45"

  const firstSelectedHour = availableHours?.length && availableHours[0].times[0]

  useEffect(() => {
    setSelectedHour(firstSelectedHour)
  }, [selectedDay])

  const handleClick = (hour: string) => {
    setSelectedHour(hour)
  }

  return (
    <Box my={2}>
      <Box mb={2}>
        <Typography variant="body2">Wybierz godzinę mycia</Typography>
      </Box>
      {hours === "" ? (
        <Typography variant="body2">brak terminów</Typography>
      ) : (
        <Grid container spacing={2} data-cy="hours-container">
          <Grid item xs={4}>
            <Box mb={2}>
              <Typography variant="h6" align="center">
                Rano
              </Typography>
            </Box>
            {hours.map((hour) => {
              const isActive = selectedHour === hour
              return (
                hour < afternoon && (
                  <Paper
                    key={hour}
                    elevation={isActive ? 7 : 6}
                    onClick={() => handleClick(hour)}
                  >
                    <Typography
                      variant={isActive ? "h5" : "h6"}
                      color={isActive ? "primary" : "initial"}
                      align="center"
                    >
                      {hour}
                    </Typography>
                  </Paper>
                )
              )
            })}
          </Grid>
          <Grid item xs={4}>
            <Box mb={2}>
              <Typography variant="h6" align="center">
                Popołudnie
              </Typography>
            </Box>
            {hours.map((hour) => {
              const isActive = selectedHour === hour
              return (
                hour >= afternoon &&
                hour < evening && (
                  <Paper
                    key={hour}
                    elevation={isActive ? 7 : 6}
                    onClick={() => handleClick(hour)}
                  >
                    <Typography
                      variant={isActive ? "h5" : "h6"}
                      color={isActive ? "primary" : "initial"}
                      align="center"
                    >
                      {hour}
                    </Typography>
                  </Paper>
                )
              )
            })}
          </Grid>
          <Grid item xs={4}>
            <Box mb={2}>
              <Typography variant="h6" align="center">
                Wieczór
              </Typography>
            </Box>
            {hours.map((hour) => {
              const isActive = selectedHour === hour
              return (
                hour >= evening && (
                  <Paper
                    key={hour}
                    elevation={isActive ? 7 : 6}
                    onClick={() => handleClick(hour)}
                  >
                    <Typography
                      variant={isActive ? "h5" : "h6"}
                      color={isActive ? "primary" : "initial"}
                      align="center"
                    >
                      {hour}
                    </Typography>
                  </Paper>
                )
              )
            })}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default SelectHour
