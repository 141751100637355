import { FC } from "react"
import { Box, Divider, Grid, Typography } from "@material-ui/core"
import { IServiceConfigurations } from "../../ts/types/LimitInformations"

type Props = {
  configurations: IServiceConfigurations[]
  title: string
  periodStart: string
  periodEnd: string
  limitTypeName: string
  isQuantity?: boolean
  isQuotaLimit?: boolean
  currencySymbol?: string
}

const LimitTile: FC<Props> = ({
  title,
  periodStart,
  periodEnd,
  limitTypeName,
  configurations,
  isQuantity,
  isQuotaLimit,
  currencySymbol,
}) => {
  return (
    <>
      <Typography variant="h3" align="center">
        {title}
      </Typography>
      <Box mt={2}>
        <Divider />
        <Box my={3}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xs={3} container alignItems="center">
              <Typography paragraph>Program</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center" paragraph>
                Limit
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right" paragraph>
                Pozostało
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />
        <Box my={2}>
          {isQuantity &&
            configurations.map((service) => {
              const isZero = service.limit_left === 0
              const textColor = isZero ? "error" : "secondary"

              return (
                <Grid
                  container
                  key={service.service_symbols}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item xs={3} container alignItems="center">
                    <Typography variant="subtitle2">
                      {service.service_symbols}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="center" variant="subtitle2">
                      {service.limit}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      align="right"
                      variant="subtitle2"
                      color={textColor}
                      data-cy="limit-left"
                    >
                      {service.limit_left}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}

          {isQuotaLimit &&
            configurations.map((service) => {
              const isNotZero = service.limit_money_left !== null || 0
              const textColor = isNotZero ? "secondary" : "inherit"
              const currency = currencySymbol ? currencySymbol : ""
              const limitMoney =
                service.limit_money === null
                  ? "-"
                  : service.limit_money + currency
              const limitMoneyLeft =
                service.limit_money_left === null
                  ? "-"
                  : service.limit_money_left + currency

              return (
                <Grid
                  container
                  key={service.service_symbols}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item xs={3} container alignItems="center">
                    <Typography variant="subtitle2">
                      {service.service_symbols}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="center" variant="subtitle2">
                      {limitMoney}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      align="right"
                      variant="subtitle2"
                      color={textColor}
                    >
                      {limitMoneyLeft}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
        </Box>

        <Divider />
      </Box>
      <Box my={3}>
        <Typography align="center" paragraph>
          Okres rozliczeniowy:
        </Typography>
        <Typography align="center" variant="subtitle2">
          {`${periodStart} - ${periodEnd}`}
        </Typography>
      </Box>

      <Divider />
      <Box my={3}>
        <Typography align="center" paragraph>
          Rodzaj limitu:
        </Typography>
        <Typography align="center" variant="subtitle2">
          {limitTypeName}
        </Typography>
      </Box>
    </>
  )
}

export default LimitTile
