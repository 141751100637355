import { FC } from "react"
import { Box, Divider, Paper } from "@material-ui/core"
import { ReactComponent as IconLogo } from "../../assets/svg/icon_logo.svg"
import FindRouteAndCallToButtonsSection from "../../components/common/FindRouteAndCallToButtonsSection"
import OrderCodeSection from "../../components/OrderCodeSection"
import { useQueryServiceOrder } from "../../api/rectQueryHooks/queries/serviceOrder"
import { useParams } from "react-router"
import { useQueryCarWashData } from "../../api/rectQueryHooks/queries/carwashData"
import OrderDetailsSection from "../../components/OrderDetailsSection"
import ActionButton from "../../components/common/ActionButton"

const Reservation: FC = () => {
  const { id, order_id } = useParams()

  const { data: carwashData } = useQueryCarWashData(id || "")
  const { data: order } = useQueryServiceOrder(order_id || "")

  if (!order || !carwashData) return null
  else
    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconLogo />
          <OrderCodeSection order={order} />
        </Box>
        <Paper elevation={0}>
          <Divider />
          <OrderDetailsSection carwashData={carwashData} order={order} />

          <FindRouteAndCallToButtonsSection
            phone={carwashData.information_phone}
            location_lat={carwashData.location_lat}
            location_lng={carwashData.location_lng}
          />
          <Divider />
          <Box mt={2.5}>
            <ActionButton
              variant="contained"
              label="Zamknij"
              color="secondary"
              fullWidth={true}
            />
          </Box>
        </Paper>
      </>
    )
}

export default Reservation
