import { IAvailableTermsAPI } from "../../ts/types/AvailableTerms"
export const mockData: IAvailableTermsAPI = {
  1: [
    {
      date: "2022-03-02",
      active: true,
      times: [
        "07:00",
        "07:15",
        "07:30",
        "07:45",
        "08:00",
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "16:45",
        "17:45",
        "18:15",
        "19:00",
        "19:30",
        "19:45",
      ],
    },
    {
      date: "2022-03-03",
      active: true,
      times: [
        "08:00",
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "16:45",
        "17:45",
        "18:15",
        "19:00",
        "19:30",
        "19:45",
      ],
    },
  ],
}
