import { FC } from "react"
import { Box } from "@material-ui/core"
import ActionButton from "../ActionButton"
import { ReactComponent as IconRoute } from "../../../assets/svg/icon_route.svg"
import { ReactComponent as IconPhone } from "../../../assets/svg/icon_phone.svg"

type Props = {
  phone: string
  location_lat: string
  location_lng: string
}
const FindRouteAndCallToButtonsSection: FC<Props> = ({
  phone,
  location_lat,
  location_lng,
}) => {
  const phoneHref = `tel:${phone}`

  const lat = parseFloat(location_lat)
  const lng = parseFloat(location_lng)

  const handleRoute = () => {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf("iPhone") != -1 ||
      navigator.platform.indexOf("iPad") != -1 ||
      navigator.platform.indexOf("iPod") != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`)
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`)
  }

  return (
    <Box display="flex" justifyContent="space-between" my={1.5}>
      <ActionButton
        variant="text"
        label="Znajdź trasę"
        color="secondary"
        icon={<IconRoute />}
        action={handleRoute}
      />
      <ActionButton
        variant="text"
        label="Zadzwoń"
        color="secondary"
        icon={<IconPhone />}
        href={phoneHref}
      />
    </Box>
  )
}

export default FindRouteAndCallToButtonsSection
