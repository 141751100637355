import { FC } from "react"
import { format } from "date-fns"
import { Box, Typography, Paper } from "@material-ui/core"

import { IOrderResponse } from "../../ts/types/OrderResponse"
import { ReactComponent as IconChevronRight } from "../../assets/svg/icon_chevron_right.svg"
import { ReactComponent as IconCalendar } from "../../assets/svg/icon_calendar.svg"
import { ReactComponent as IconCar } from "../../assets/svg/icon_car.svg"
import { useNavigate } from "react-router"

type Props = {
  order: IOrderResponse
}

const OrdersHistoryTile: FC<Props> = ({ order }) => {
  const { service_symbol, created_dt, car_plate, is_used, is_valid, id } = order
  const navigate = useNavigate()
  const date = new Date(created_dt)
  const isActive = !is_used && is_valid
  const isUsed = is_used
  const isExpired = !is_valid

  const statusLabel =
    (isActive && "Aktywne") ||
    (isUsed && "Wykorzystane") ||
    (isExpired && "Wygasłe")

  const textColor = isActive ? "secondary" : isUsed ? "textSecondary" : "error"

  return (
    <Paper elevation={11}>
      <Box
        display="flex"
        justifyContent="space-between"
        data-cy="history-order-tile"
        // TODO: carwash id to url
        onClick={() => navigate(`/carwash/2356/reservation/${id} `)}
      >
        <Box>
          <Box display="flex" gridGap={8} alignItems="center">
            <Paper elevation={8}>
              <Typography variant="h5" color={textColor}>
                {service_symbol}
              </Typography>
            </Paper>
            <Typography variant="h5" color={textColor}>
              {statusLabel}
            </Typography>
          </Box>
          <Box display="flex" gridGap={8} my={1.5}>
            <Box mx={0.5}>
              <IconCalendar />
            </Box>
            <Typography>{format(date, "dd.MM.yyyy, HH:mm")}</Typography>
          </Box>
          <Box display="flex" gridGap={8}>
            <Box mx={0.5}>
              <IconCar />
            </Box>
            <Typography>{car_plate}</Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexGrow={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconChevronRight />
        </Box>
      </Box>
    </Paper>
  )
}

export default OrdersHistoryTile
