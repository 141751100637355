import { FC } from "react"

import { Box, Typography } from "@material-ui/core"

import { ICarWash } from "../../ts/types/CarWash"

type Props = {
  carWashData: ICarWash
}

const CarWashDetailsSection: FC<Props> = ({ carWashData }) => {
  const { name, id, information_address } = carWashData

  return (
    <Box mb={2.5}>
      <Typography variant="body2">Wybrana myjnia</Typography>
      <Box my={0.5}>
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Typography paragraph>
        {information_address} <br /> Nr myjni&nbsp;{id}
      </Typography>
    </Box>
  )
}

export default CarWashDetailsSection
