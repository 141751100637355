import { FC } from "react"
import { Navigate } from "react-router-dom"

import { RoutePath } from "./Routes.types"

interface IProps {
  authenticated: boolean
}

const PrivateRoute: FC<IProps> = ({ authenticated, children }) => {
  if (!authenticated) return <Navigate to={RoutePath.LOGIN} />

  return <>{children}</>
}

export default PrivateRoute
