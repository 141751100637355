import { FC } from "react"

import { Box, Typography } from "@material-ui/core"

import { ReactComponent as IconMaxheight } from "../../assets/svg/icon_maxheight.svg"

type Props = {
  max_car_height: number
}

const MaxHeightSection: FC<Props> = ({ max_car_height }) => {
  return (
    <Box display="flex" gridGap={18}>
      <IconMaxheight />
      <Typography variant="body1">max. {max_car_height} cm</Typography>
    </Box>
  )
}

export default MaxHeightSection
