import { FC } from "react"
import { useParams } from "react-router-dom"

import { Box, Divider, Drawer, Typography } from "@material-ui/core"

import ActionButton from "../../common/ActionButton"
import CarWashFeaturesSection from "../../CarWashFeaturesSection"

import { ICarWash } from "../../../ts/types/CarWash"
import StatusBadge from "../../common/StatusBadge"
import FindRouteAndCallToButtonsSection from "../../common/FindRouteAndCallToButtonsSection"
import { useCars } from "../../../context/AppContext"

type Props = {
  toggleDrawerOpen: boolean
  setToggleDrawerOpen: (prev: boolean) => void
  carWashData: ICarWash
}

const CarWashDetailsDrawer: FC<Props> = ({
  toggleDrawerOpen,
  setToggleDrawerOpen,
  carWashData,
}) => {
  const {
    name,
    waiting_room,
    wifi,
    car_spa,
    disinfection,
    open_24h,
    tire_service,
    ozonation,
    shopping_center,
    max_car_height,
    information_address,
    opening_hours,
    information_phone,
    location_lat,
    location_lng,
  } = carWashData

  const { id } = useParams()
  const { distance } = useCars()

  // TODO: add logic to action buttons

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={toggleDrawerOpen}
      onClose={() => setToggleDrawerOpen(false)}
    >
      <Divider light />
      <Box mt="10px" mb={2}>
        <Typography variant="h2" color="inherit">
          {name}
        </Typography>
      </Box>
      <Divider />
      <Box my={2}>
        <CarWashFeaturesSection
          waiting_room={waiting_room}
          wifi={wifi}
          car_spa={car_spa}
          disinfection={disinfection}
          open_24h={open_24h}
          tire_service={tire_service}
          ozonation={ozonation}
          shopping_center={shopping_center}
          max_car_height={max_car_height}
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography variant="body1">
          {information_address} {distance !== "NaN" && `(${distance} km)`}
        </Typography>

        <StatusBadge open_24h={open_24h} opening_hours={opening_hours} />
      </Box>
      <Divider />
      <FindRouteAndCallToButtonsSection
        phone={information_phone}
        location_lat={location_lat}
        location_lng={location_lng}
      />
      <Divider />
      <Box mt={2.5}>
        <ActionButton
          variant="contained"
          label="Zamów i zarezerwój mycie"
          color="secondary"
          fullWidth={true}
          href={`/carwash/${id}/order`}
        />
      </Box>
    </Drawer>
  )
}

export default CarWashDetailsDrawer
