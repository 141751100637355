import { useMatch, useNavigate } from "react-router-dom"
import { IconButton, Paper } from "@material-ui/core"
import { ReactComponent as IconPin } from "../../../assets/svg/icon_pin.svg"
import { ReactComponent as IconPinActive } from "../../../assets/svg/icon_pin_active.svg"
import { ReactComponent as IconHeart } from "../../../assets/svg/icon_heart.svg"
import { ReactComponent as IconHeartActive } from "../../../assets/svg/icon_heart_active.svg"
import { ReactComponent as IconWallet } from "../../../assets/svg/icon_wallet.svg"
import { ReactComponent as IconWalletActive } from "../../../assets/svg/icon_wallet_active.svg"
import { ReactComponent as IconHistory } from "../../../assets/svg/icon_history.svg"
import { ReactComponent as IconHistoryActive } from "../../../assets/svg/icon_history_active.svg"
import { ReactComponent as IconMore } from "../../../assets/svg/icon_more.svg"
import { ReactComponent as IconMoreActive } from "../../../assets/svg/icon_more_active.svg"
import { RoutePath } from "../../../routes/Routes.types"

const BottomMenu = () => {
  const navigate = useNavigate()
  const menuItemsConfig = [
    {
      icon: <IconPin />,
      activeIcon: <IconPinActive />,
      label: "pin-icon",
      path: RoutePath.CARWASH,
      match: useMatch({ path: RoutePath.CARWASH, end: true }),
    },
    {
      icon: <IconHeart />,
      activeIcon: <IconHeartActive />,
      label: "heart-icon",
      path: RoutePath.FAVORITE,
      match: useMatch({ path: RoutePath.FAVORITE, end: true }),
    },
    {
      icon: <IconWallet />,
      activeIcon: <IconWalletActive />,
      label: "wallet-icon",
      path: RoutePath.LIMITS,
      match: useMatch({ path: RoutePath.LIMITS, end: true }),
    },
    {
      icon: <IconHistory />,
      activeIcon: <IconHistoryActive />,
      label: "history-icon",
      path: RoutePath.ORDERS,
      match: useMatch({ path: RoutePath.ORDERS, end: true }),
    },
    {
      icon: <IconMore />,
      activeIcon: <IconMoreActive />,
      label: "more-icon",
      path: RoutePath.MORE,
      match: useMatch({ path: RoutePath.MORE, end: true }),
    },
  ]

  return (
    <Paper elevation={14}>
      {menuItemsConfig.map((item) => {
        const isMatch = item.match

        return (
          <IconButton
            key={item.label}
            edge={isMatch ? "start" : "end"}
            onClick={() => navigate(item.path)}
          >
            {isMatch ? item.activeIcon : item.icon}
          </IconButton>
        )
      })}
    </Paper>
  )
}

export default BottomMenu
