import { useMutation, useQueryClient } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"

import { IReservationCancelResponse } from "../../../ts/types/ReservationCancelResponse"

const token = {
  access:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NTgyNjM1LCJqdGkiOiI4NDFkNjc2MzIyZmQ0MTY4OGI3YzFjOGMzNDE1YWQ1MyIsImRyaXZlciI6NDAxNTN9.vpqZroqhHC_1D-rpQaNLepQPpWtK_2X5FkZK3foc6Hg",
}

export const useMutationReservationCancel = () => {
  const queryClient = useQueryClient()
  return useMutation<IReservationCancelResponse, AxiosError, any>(
    async (id) => {
      const response = await axios.delete(`/carwash/booking/${id}/`, {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      })
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("service-all-orders")
      },
    }
  )
}
