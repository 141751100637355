import { FC } from "react"
import { useMatch, useParams, useNavigate } from "react-router-dom"

import { Box, Divider, IconButton, Typography } from "@material-ui/core"

import { useQueryCarWashData } from "../../../api/rectQueryHooks/queries/carwashData"

import { ReactComponent as IconBack } from "../../../assets/svg/icon_back.svg"
import { colors } from "../../../themes/default/colors"

import { RoutePath } from "../../../routes/Routes.types"

const Navbar: FC = () => {
  const { id } = useParams()
  const { data } = useQueryCarWashData(id || "")

  const orderTitle = data?.online_booking
    ? "Zamów i zarezerwój mycie"
    : "Zamów mycie"

  const routesConfig = [
    {
      title: "",
      path: RoutePath.CARWASH,
      match: useMatch({ path: RoutePath.CARWASH, end: true }),
    },
    {
      title: orderTitle,
      path: RoutePath.ORDER,
      match: useMatch({ path: RoutePath.ORDER, end: true }),
    },
    {
      title: orderTitle,
      path: RoutePath.SELECT_DATE,
      match: useMatch({ path: RoutePath.SELECT_DATE, end: true }),
    },
    {
      title: "Zamówienie",
      path: RoutePath.RESERVATION,
      match: useMatch({ path: RoutePath.RESERVATION, end: true }),
    },
    {
      title: "Zamówienia",
      path: RoutePath.ORDERS,
      match: useMatch({ path: RoutePath.ORDERS, end: true }),
    },
    {
      title: "",
      path: RoutePath.LIMITS,
      match: useMatch({ path: RoutePath.LIMITS, end: true }),
    },
    {
      title: "Więcej",
      path: RoutePath.MORE,
      match: useMatch({ path: RoutePath.MORE, end: true }),
    },
  ]

  const activeRouteConfig = routesConfig.find((el) => el.match)
  const sectionTitle = activeRouteConfig?.title || null
  const limitsPage = activeRouteConfig?.path === RoutePath.LIMITS

  const boxColor = sectionTitle ? colors.white : "transparent"
  const isNavVisible = limitsPage ? "none" : "flex"

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Box
        display={isNavVisible}
        justifyContent="space-between"
        alignItems="center"
        p={1.5}
        sx={{ bgcolor: boxColor }}
      >
        <IconButton aria-label="back" color="primary" onClick={handleBack}>
          <IconBack />
        </IconButton>
        <Box display="flex" flexGrow={2} justifyContent="center">
          <Typography variant="h2" color="inherit">
            {sectionTitle}
          </Typography>
        </Box>
      </Box>
      {sectionTitle && <Divider />}
    </>
  )
}

export default Navbar
