import { FC } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { Box } from "@material-ui/core"
import IconPinGoogle from "../../assets/svg/icon_pin_google.svg"
import mapStyles from "./mapStyles"
import useGeoLocation from "../../hooks/useGeoLocation"
import { useCars } from "../../context/AppContext"

const libraries = ["places", "geometry"]

const mapContainerStyle = {
  width: "100vw",
  height: "93vh",
}

const options = {
  styles: mapStyles,
}

type Props = {
  location_lat: string
  location_lng: string
  setToggleDrawerOpen: any
}

const MapSection: FC<Props> = ({
  location_lat,
  location_lng,
  setToggleDrawerOpen,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    //   @ts-ignore
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    // @ts-ignore
    libraries,
  })

  const adressLongLat = {
    lat: parseFloat(location_lat),
    lng: parseFloat(location_lng),
  }

  const location = useGeoLocation()

  const userPosition = {
    //@ts-ignore
    lat: parseFloat(location.coordinates?.lat),
    //@ts-ignore
    lng: parseFloat(location.coordinates?.lng),
  }
  const { setDistance } = useCars()

  if (isLoaded && userPosition && adressLongLat) {
    const distanceInMeters =
      google.maps.geometry.spherical.computeDistanceBetween(
        //@ts-ignore
        userPosition,
        adressLongLat
      )

    const distanceInKm = (distanceInMeters / 1000).toFixed(1).replace(".", ",")
    setDistance(distanceInKm)
  }

  if (loadError) return <p>Error loading maps</p>
  if (!isLoaded) return <p>loading maps</p>

  return (
    <Box position="absolute" top={0}>
      <GoogleMap
        zoom={17}
        center={adressLongLat}
        mapContainerStyle={mapContainerStyle}
        options={options}
      >
        <Marker
          position={adressLongLat}
          icon={{
            url: IconPinGoogle,
            scaledSize: new window.google.maps.Size(80, 80),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(40, 65),
          }}
          onClick={() => setToggleDrawerOpen(true)}
        />
      </GoogleMap>
    </Box>
  )
}

export default MapSection
