import { useQuery, UseQueryResult } from "react-query"
import { ILimitInformations } from "../../../ts/types/LimitInformations"
import { IOrderResponse } from "../../../ts/types/OrderResponse"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"

const token = {
  access:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NTgyNjM1LCJqdGkiOiI4NDFkNjc2MzIyZmQ0MTY4OGI3YzFjOGMzNDE1YWQ1MyIsImRyaXZlciI6NDAxNTN9.vpqZroqhHC_1D-rpQaNLepQPpWtK_2X5FkZK3foc6Hg",
}

export const useQueryLimitInformation = (
  car_id: number | string,
  options: any = {}
) => {
  const result: UseQueryResult<ILimitInformations[], Error> = useQuery<
    ILimitInformations[],
    Error
  >(
    ["limit-information", car_id],
    async () => {
      const res = await axios("/car/" + car_id + Endpoint.LIMIT_INFORMATION, {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      })
      return res.data
    },
    {
      staleTime: 20 * 1000,
      ...options,
    }
  )
  return result
}
