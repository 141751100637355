import React, { FC } from "react"
import { Box, Divider, Drawer, Typography } from "@material-ui/core"
import ActionButton from "../common/ActionButton"
import { useMutationReservationCancel } from "../../api/rectQueryHooks/mutations/reservationCancel"
import { useNavigate, useParams } from "react-router"

type Props = {
  toggleDrawerOpen: boolean
  setToggleDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const CancelReservationDrawer: FC<Props> = ({
  toggleDrawerOpen,
  setToggleDrawerOpen,
}) => {
  const { order_id } = useParams()
  const navigate = useNavigate()

  const { mutate: cancelReservation } = useMutationReservationCancel()

  const handleDelete = (e: any) => {
    e.preventDefault()
    cancelReservation(order_id)
    navigate("/orders/")
  }

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={toggleDrawerOpen}
      onClose={() => setToggleDrawerOpen(false)}
    >
      <Divider light />
      <Box mt={2}>
        <Box px={4}>
          <Typography variant="h2" color="inherit" align="center">
            Czy zrezygnujesz z rezerwacji terminu?
          </Typography>
        </Box>
        <Box mt={2} mb={3}>
          <Typography align="center" variant="h6">
            Jeśli to zrobisz będziesz móc zarezerwować mycie w innym terminie,
            maksymalnie do daty ważności zamówienia. Możesz też skontaktować się
            bezpośrednio z myjnią
          </Typography>
        </Box>
        <ActionButton
          variant="outlined"
          label="Nie, nie rezygnuję z rezerwacji"
          color="secondary"
          fullWidth
          action={() => setToggleDrawerOpen(false)}
        />
        <Box mt={2.5}>
          <ActionButton
            variant="contained"
            label="Tak, rezygnuję z rezerwacji"
            color="secondary"
            fullWidth
            action={handleDelete}
          />
        </Box>
      </Box>
    </Drawer>
  )
}

export default CancelReservationDrawer
