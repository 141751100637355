import { FC } from "react"
import { format } from "date-fns"

import { Box, Divider, Typography } from "@material-ui/core"
import StatusBadge from "../common/StatusBadge"

import { ICarWash } from "../../ts/types/CarWash"
import { IOrderResponse } from "../../ts/types/OrderResponse"

type Props = {
  carwashData: ICarWash
  order: IOrderResponse
}

const OrderDetailsSection: FC<Props> = ({ carwashData, order }) => {
  const createdDt = new Date(order.created_dt)
  const createdDate = format(createdDt, "dd.MM.yyyy, HH:mm")

  const validDt = new Date(order.valid_dt)
  const validDate = format(validDt, "dd.MM.yyyy, HH:mm")

  return (
    <Box pt="6px" pb="10px">
      <Box display="flex" justifyContent="space-between" my="10px">
        <Typography variant="h6">Program:</Typography>
        <Typography variant="h4">{order.service_symbol}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="10px">
        <Typography variant="h6">Samochód:</Typography>
        <Typography variant="h4">{order.car_plate}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="10px">
        <Typography variant="h6">Data zamówienia:</Typography>
        <Typography variant="h4">{createdDate}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="10px">
        <Typography variant="h6">Data ważności:</Typography>
        <Typography variant="h4">{validDate}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="10px">
        <Typography variant="h6">Numer myjni:</Typography>
        <Typography variant="h4">M{order.carwash_code}</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Box>
          <Typography variant="subtitle2">{carwashData.name}</Typography>
          <Typography variant="body2">
            {`${carwashData.information_address}, ${carwashData.information_city}`}
          </Typography>
        </Box>
        <StatusBadge
          open_24h={carwashData.open_24h}
          opening_hours={carwashData.opening_hours}
        />
      </Box>
      <Divider />
    </Box>
  )
}

export default OrderDetailsSection
