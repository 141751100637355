import { FC, useState } from "react"
import { useParams } from "react-router-dom"

import { Button } from "@material-ui/core"

// import CarWashDetailsDrawer from "../../components/CarWashDetailsDrawer"
import { useQueryCarWashData } from "../../api/rectQueryHooks/queries/carwashData"

const HomepagePage: FC = () => {
  const { id } = useParams()

  const [toggleDrawerOpen, setToggleDrawerOpen] = useState(true)
  const handleDrawerOpen = () => {
    setToggleDrawerOpen((prev) => !prev)
  }

  //@ts-ignore
  const { data, isLoading, isError } = useQueryCarWashData(id)
  if (!data) return null
  else
    return (
      <>
        <p>homepage</p>
        <Button onClick={handleDrawerOpen}>Drawer button</Button>
      </>
    )
}

export default HomepagePage
